<template>
	<div>
		<SideBarLeft v-if="$route.name === 'catalog'" :pevents="events" />

		<SideBarRight v-if="$route.name === 'catalog'" :pevents="events" />

		<router-view :pevents="events"/>

		<Button
				v-if="$route.name === 'catalog'"
				:classes="drawer ? '':'transition-rotate'"
				iconVal="mdi-arrow-expand-left"
				carre
				fixed
				:top="52"
				:left="drawer ? 204 : 8"
				:styles="{zIndex: 4}"
				:small="true"
				:click="() => {drawer = !drawer}"
				iconSmall
				:color="drawer ? 'transparent':''"
				:flat="drawer"
		/>

		<Button
				v-if="$route.name === 'catalog' && contact_id"
				iconVal="mdi-cog"
				carre
				fixed
				:bottom="64"
				:right="drawer_ads ? 234 : 12"
				:styles="{zIndex: 4}"
				:click="() => {drawer_ads = !drawer_ads; drawer = !drawer_ads}"
				iconSmall
		/>
	</div>
</template>

<script>
	export default {
		name: "MarketPlace",
		components: {
			SideBarLeft: () => import('@/layout/marketplace/SideBarLeft.vue'),
			SideBarRight: () => import('@/layout/marketplace/SideBarRight.vue'),
			Button: () => import('@/components/ui/Button.vue')
		},
		data(){
			return {
				events: null
			}
		},
		computed: {
			drawer: {
				get() {
					return this.$store.getters['marketplace/drawer']
				},
				set(val) {
					this.$store.commit('marketplace/CHANGE_DRAWER_VAL', val)
				}
			},
			drawer_ads: {
				get(){
					return this.$store.getters['marketplace/drawer_ads']
				},
				set(val) {
					this.$store.commit('marketplace/CHANGE_DRAWER_ADS_VAL', val)
				}
			},
			contact_id(){
				return this.$store.getters['auth/contact_id']
			},
			ready(){
				return this.$store.getters.application_ready
			}
		},
		watch: {
			ready(val){
				if(!val){
					this.events.notify('update-app')
				}
			}
		},
		methods: {
			setUpHooks(){
				this.events = new this.$NVEvent('MarketPlace')
			}
		},
		created() {
			this.setUpHooks()
		}
	}
</script>

<style scoped>

</style>
